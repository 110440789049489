import React from "react";
import {IMAGE_PREFIX, LOGIN_URL} from "../constants/urls.constants";

type ExistingAccountProps = {  workflow: Object };
type ExistingAccountState = {intervalId: any};

class ExistingAccount extends React.Component<ExistingAccountProps, ExistingAccountState> {
  handleClick(event) {
    event.preventDefault()

    window.location.href = LOGIN_URL;
  }

  detailsFromWorkflow() {
    const template = this.props.workflow["steps"][0]["ui_template_type"];
    const step = this.props.workflow["steps"][0][template];

    let default_state = {
      title: step.default_state['title'],
      first_action_title: step.default_state['first_action_title'],
      image_name: step.image_name
    }

    return default_state;
  }


  render() {
    const appConfig = window['appConfig'] ?? {};
    const details = this.props.workflow ?  this.detailsFromWorkflow() : {title:  'Looks like you already have an account with us. Please sign in to continue.', first_action_title: 'Continue', image_name: 'onbCardDeclinedPlaceholder'};
    const imgSrc = `${IMAGE_PREFIX}/${details.image_name}.png`;

    return (
      <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page text-center`}>
        <p className="text-main mt-6 mb-4 text-1-heading" data-testid="title">
          {details.title}
        </p>
        <img className="w-3/4 mx-auto mb-4 lg:w-1/2" src={imgSrc} alt="existing-account"/>
        <button className="bg-theme rounded-full py-3 px-28 text-white focus:outline-none brand-btn-solid" onClick={this.handleClick} data-testid="action-title">
          <label>{details.first_action_title}</label>
        </button>
      </div>
    );
  }
}

export default ExistingAccount;
