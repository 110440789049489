import { setAMCThemeVars } from "./style-vars/amc.vars";
import { setBlockfiThemeVars } from "./style-vars/blockfi.vars";
import { setCCBankThemeVars } from "./style-vars/ccbank.vars";
import { setCustomersBankThemeVars } from "./style-vars/customers_bank.vars";
import { setDefaultThemeVars } from "./style-vars/default.vars";
import { setDeserveThemeVars } from "./style-vars/deserve.vars";
import { setDeserveEduThemeVars } from "./style-vars/deserve_edu.vars";
import { setEarnestThemeVars } from "./style-vars/earnest.vars";
import { setCreditKrowdfitThemeVars } from "./style-vars/krowdfit.vars";
import { setOppfiThemeVars } from "./style-vars/oppfi.vars";
import { setSLMIgniteThemeVars } from "./style-vars/slm_ignite.vars";
import { setSWThemeVars } from "./style-vars/sw.vars";

function loadTheme(context) {
  //TODO: Change submit button color according to color.
  setDefaultThemeVars();

  switch (context) {
    case "deserve":
      setDeserveThemeVars();
      break;
    case "blockfi":
      setBlockfiThemeVars();
      break;
    case "sw":
      setSWThemeVars();
      break;
    case "earnest":
      setEarnestThemeVars();
      break;
    case "oppfi":
      setOppfiThemeVars();
      break;
    case "krowdfit":
      setCreditKrowdfitThemeVars();
      break;
    case "customers_bank":
      setCustomersBankThemeVars();
      break;
    case "amc":
      setAMCThemeVars();
      break;
    case "slm_ignite":
      setSLMIgniteThemeVars();
      break;
    case "deserve_edu":
      setDeserveEduThemeVars();
      break;
    case "ccbank":
      setCCBankThemeVars();
      break;
    default:

      break;
  }
}

export default loadTheme;
