import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import {commonHeaders, get} from "../utils/client";
import SnackPopMessage from '../components/SnackPopMessage';
import Cookies from "js-cookie";
import {
  ADDRESS_INPUT_LABEL,
  STREET_API_SYSTEM_DOWN_LABEL
} from '../constants/label.constants';

import {
  ERROR_ADDRESS_NOT_FOUND,
  ERROR_STATE_NOT_SUPPORTED
} from '../constants/error.constants';
import { track } from "../utils/analytics";
import BtnSpinnerNext from '../components/BtnSpinnerNext';
import { EVENT } from "../constants/events.constants";
import { PROFILE_ADDRESS_UPDATE_API, PROFILE_API_URL } from '../constants/urls.constants';
import { ButtonDone } from '../components/ButtonDone';
import ArrowLeft from '../components/ArrowLeft';
import axios from 'axios';
import Loading from '../components/Loading';
import PaymentError from './Payment_Error';
import { EDIT_ADDRESS_PATH } from '../constants/route-paths.constants';
import { workflow } from '../constants/workflow.constants';
import { AddressType } from '../types/AddressType';
import SmartStreetAutoComplete from '../components/SmartStreetAutoComplete';
import {getApplicationToken, isOnboardingRefreshV2Enabled} from '../utils/helpers';

type Props = { partnerName: String };

function EditAddress(props: Props) {
  const [hasError, set_hasError] = useState(false);
  const [zip_code, set_zip_code] = useState('');
  const [physical_address, set_physical_address] = useState('');
  const [search_address, set_search_address] = useState('');
  const [address_line_1, set_address_line_1] = useState('');
  const [address_line_2, set_address_line_2] = useState('');
  const [city, set_city] = useState('');
  const [state, set_state] = useState('');
  const [loading, setLoading] = useState(false);
  const [isStateSupported, setIsStateSupported] = useState(true);
  const [poBoxError, setPoBoxError] = useState(false);
  const [streetApiOffline, setStreetApiOffline] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [regexPOErrorMessage, setRegexPOErrorMessage] = useState('');
  const [title, setTitle] = useState('');
  const [maxLength, setMaxLength] = useState();
  const [minLength, setMinLength] = useState();
  const [zipCodeRegex, setZipCodeRegex] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [validStates, setValidStates] = useState([]);
  const [inputEmpty, setInputEmpty] = useState(true);
  const regexPO = ["po box"];
  const [showProfileError, setShowProfileError] = useState(false);
  const arrowColor = document.body.style.getPropertyValue("--theme-card-text")

  const {
    register,
    handleSubmit,
  } = useForm({
    mode: 'all',
  });

  const setAddressInputValues = useCallback(
    (address: AddressType) => {
      set_address_line_1(address.address_line_1);
      set_address_line_2(address.address_line_2);
      set_zip_code(address.zipcode || "");
      set_city(address.city || "");
      set_state(address.state || "");
      set_search_address(address.search_address || "");
    },
    [],
  );

  const setAddress = useCallback(
    (address: AddressType, option: any) => {
      if (address && Object.keys(address).length) {
        setAddressInputValues(address);
        set_hasError(false);
        if(validStates.length > 0) {
          setIsStateSupported(validStates.includes(address.state));
        }
      } else {
        setAddressInputValues({});
        set_hasError(true);
      }
      setLoading(false);
    },
    [setAddressInputValues, validStates]
  );

  const getProfile = useCallback(
    async (): Promise<any> => {
      setLoading(true);
      return get(PROFILE_API_URL)
        .then((accountDataRes) => {
          const physical_address = accountDataRes.sections[0]?.cells?.find(item => item.identifier === "profile_physical_address")?.subtitle;
          set_physical_address(physical_address);
          return accountDataRes;
        })
    },
    [],
  )

  useEffect(() => {
    getProfile().then((accountDataRes) => {
      let details = accountDataRes.sections.filter(x => x.title === "DETAILS")[0]?.cells.filter(x => x.title === "Address");
      let content = details[0].detail.content[0];
      let cell = content['profile_template_a']?.cells;
      let statesData = cell[2];

      let address = {
        search_address: cell[0].value,
        city: cell[1].value,
        state: cell[2].value,
        zipcode: cell[3].value,
      };

      setRegexPOErrorMessage(content['profile_template_a'].default_state.subtitle);
      setTitle(content['profile_template_a'].default_state.title);
      setMaxLength(cell[3].max_length);
      setMinLength(cell[3].min_length);
      setZipCodeRegex(cell[3].validation_regex);

      let _validStates = statesData.picker_options.map((state) => {
        return state.identifier;
      });

      setValidStates(_validStates);

       setTimeout(() => { // TODO: Needs to improve rendering
        setAddress(address, null);
      }, 500);
      setLoading(false);
    })
      .catch((error) => {
        track(EVENT.onb_edit_address_page_load_failed, {error: JSON.stringify(error, Object.getOwnPropertyNames(error)),});
        setShowProfileError(true);
        setLoading(false);
      })
  }, []);

  const application_token = getApplicationToken();

  const handleBack = () => {
    window.location.assign('/dfc/profile');
  }

  const validateAndSubmit = () => {
    setDisableButton(true);
    let patchData = {
      "address": {
        'zip': zip_code,
        'city': city,
        'state': state,
        'address_line_1': address_line_1,
        'address_line_2': address_line_2,
        "country": "US",
        'type': "RESIDENCE"
      }
    };

    if (patchData?.address?.address_line_1?.length > 0 && !hasError && isStateSupported && !poBoxError) {
      setSubmitting(true);
      track(EVENT.onb_address_next_clicked, {address: patchData?.address});

      axios.patch(`${PROFILE_ADDRESS_UPDATE_API}`, patchData, {
        headers: {
          Authorization: `Bearer ${application_token}`,
          ...commonHeaders()
        }
      })
        .then((res) => {
          setSubmitting(false);
          window.location.assign('/dfc/profile');
          setDisableButton(false);
        })
        .catch((err) => {
          setSubmitting(false);
          setDisableButton(false);
        })
    }
  }

  async function onAddressSelect(option: any, inputValue: string, streetApiOffline: boolean) {
    const _hasError = regexPO.some((term) => inputValue?.toLowerCase()?.includes(term));

    setStreetApiOffline(streetApiOffline);
    setPoBoxError(_hasError);
    set_hasError(_hasError || streetApiOffline);

    let address = {
      search_address: option.selectedItem?.streetLine,
      address_line_1: option.selectedItem?.streetLine,
      address_line_2: option.selectedItem?.secondary,
      city: option.selectedItem?.city,
      state: option.selectedItem?.state,
      zipcode: option.selectedItem?.zipcode,
    };

    setAddress(address, option);
  }

  function getErrorMessage() {
    if ((hasError && !streetApiOffline && !poBoxError)) {
      return (<small className="error-block-edit-address">{ERROR_ADDRESS_NOT_FOUND}</small>);
    } else if ((!streetApiOffline && poBoxError)) {
      return (<small className="error-block-edit-address">{regexPOErrorMessage}</small>);
    }
    return null;
  }

  return (
    loading ? <Loading /> :
      <div className={`dashboard-${props.partnerName} dashboard-profile-${props.partnerName} flex x-sm:block`}>
        <div
          data-testid="mainDiv"
          className={`edit-address-container h-screen relative flex flex-col x-sm:w-full
            lg:w-3/4 pl-10 pb-8 pt-6 x-sm:pl-4 x-sm:pt-4 dashboard-profile-${props.partnerName}
            dashboard-edit-address ${poBoxError && 'select-error'}`
          }>
          <div className={`back-button-arrow opacity-100`} onClick={handleBack} >
            <ArrowLeft fillColor={arrowColor} />
          </div>
          <h1 data-testid="addressTitle" className="text-main-edit-address">
            {title}
          </h1>
          <p className="">
            {regexPOErrorMessage}
          </p>
          <p className="mb-10"><small></small></p>
          {streetApiOffline && <SnackPopMessage
            message={STREET_API_SYSTEM_DOWN_LABEL}
            styleClasses="w-7/12 bg-white absolute top-0 right-0 p-4 rounded rounded-3xl text-base ease-out dashboard-edit-address-lookup" />}
          <div className="">
            <form className="w-11/12" onSubmit={handleSubmit(validateAndSubmit)}>
              <div className="flex flex-row space-x-4 x-sm:mb-11 md:mb-12">
                <label className="w-full">
                  <div className="text-main-address uppercase mb-1 tracking-wide text-c1 theme-color-br1">{ADDRESS_INPUT_LABEL}</div>
                  <SmartStreetAutoComplete
                    defaultValue={search_address || physical_address}
                    onAddressSelect={onAddressSelect} />

                  {getErrorMessage()}
                </label>
              </div>
              <div className={`${search_address === '' ? 'invisible' : ''} flex flex-row x-sm:flex-col md:space-x-4 mb-8`}>
                <label className="w-10/12 x-sm:mb-8">
                  <div
                    className="uppercase mb-2 tracking-wide text-c1 theme-color-br1">{workflow.address.city}</div>
                  <input
                    disabled
                    type="text"
                    name="city"
                    value={city}
                    className="bg-transparent opacity-50 w-full h-12 text-3xl text-web"
                    {...(register("city", { required: true }) as any)}
                  />
                </label>
                <label className="w-4/6">
                  <div
                    className="uppercase mb-2 tracking-wide text-c1 theme-color-br1">{workflow.address.state}</div>
                  <input
                    disabled
                    type="text"
                    value={state}
                    name="state"
                    className="bg-transparent w-full opacity-50 h-12 text-3xl text-web"
                    {...(register("state", {
                      required: true
                    }) as any)}
                  />
                  {!isStateSupported ? <small className="error-block-edit-address">{ERROR_STATE_NOT_SUPPORTED}</small> : null}
                </label>
              </div>
              <div className={`${search_address === '' ? 'invisible' : ''} flex flex-row md:space-x-4 mb-8`}>
                <label className="">
                  <div
                    className="uppercase mb-2 tracking-wide text-c1 theme-color-br1">{workflow.address.zip}</div>
                  <input
                    disabled
                    type="text"
                    name="zip_code"
                    value={zip_code}
                    className="bg-transparent opacity-50 w-full h-12 text-3xl text-web"
                    {...(register("zip_code", {
                      required: true,
                      maxLength: maxLength,
                      minLength: minLength,
                      pattern: new RegExp(zipCodeRegex)
                    }) as any)}
                  />
                </label>
              </div>
              <div className="w-full flex justify-around items-center pt-10">
                {!submitting && <div>
                  <ButtonDone data-testid="doneButton" disabled={disableButton || hasError || !isStateSupported || poBoxError} className={!search_address.length || hasError || !isStateSupported || poBoxError ? 'opacity-25 inline-block' : ''} onClick={validateAndSubmit} />
                </div>}
                {submitting && <BtnSpinnerNext />}
              </div>
            </form>
          </div>
        </div>
        {showProfileError && <PaymentError
          makePayment={() => window.location.assign(EDIT_ADDRESS_PATH)}
          partnerName={props.partnerName}
          type="profile"
        />}
      </div>
  )
};

export default EditAddress;
