import React, { useEffect, useState, useRef } from "react";
import { AUTO_PAY, DASHBOARD_PATH } from "../constants/route-paths.constants";
import LoadingIcon from "../components/Loading";
import LinkBankAccountButton from "./LinkBankAccountButton";
import { commonHeaders, get, postWithAuthHeader } from "../utils/client";
import ClientStorage from "../utils/client-storage";
import PaymentError from "./Payment_Error";
import { EVENT } from "../constants/events.constants";
import { track } from "../utils/analytics";
import BtnNext from "../components/BtnNext";
import Cookies from "js-cookie";
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import ReactHtmlParser from "html-react-parser";
import { autopayConst } from "../constants/autopay.constants";
import AutopaySuccess from "./Autopay_Success";
import MigrationInProgress from "./MigrationInProgress";
import IconCheck from "../components/IconCheck";
import IconUnCheck from "../components/IconUnCheck";


import {
  GET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD,
  GET_LINK_TOKEN,
  IMAGE_PREFIX,
  PAYMENT_API,
  CANCEL_AUTOPAY,
  UPDATE_AUTOPAY,
  GET_AUTOPAY_STATUS,
  STATEMENTS_API_URL,
  IMAGE_BASE_URL,
  DASHBOARD_API_URL,
  PAYMENT_V3_API_URL,
  ACCOUNT_V3_API_URL
} from "../constants/urls.constants";
import {appSentryCaptureError, getApplicationToken} from "../utils/helpers";
import { getWhiteLogo } from "../utils/utils";


const Autopay = (props) => {
  const [minPaymentAmount, setMinPaymentAmount] = useState(null);
  const [statementBalAmount, setStatementBalAmount] = useState(null);
  const [selected, setSelected] = useState('');
  const [isStatements, setIsStatements] = useState<boolean>(false);
  const [isAutopayAvailable, setIsAutopayAvailable] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(true);
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [dueDate, setDueDate] = useState<any>('na');
  const [enrollButtonDisabled, setEnrollButtonDisabled] = useState<boolean>(true);
  const [showCancelUpdateButton, setShowCancelUpdateButton] = useState(false);
  const [showPaymentFailure, setShowPaymentFailure] = useState(false);
  const [autoPayRespData, setAutoPayRespData] = useState(null);
  const [autopayScheduleId, setAutopayScheduleId] = useState<string>('');
  const [enrolledSelectedMethod, setEnrolledSelectedMethod] = useState<string>('');
  const [displayMessages, setDisplayMessages] = useState<any>({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [disclaimer, setDisclaimer] = useState('');
  const [autopayStatusData, setAutopayStatusData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [showAutopaySuccess, setShowAutopaySuccess] = useState(false);
  const [autopaySuccessData, setAutopaySuccessData] = useState(null);
  const [showMigrationError, setShowMigrationError] = useState(false);
  const [userConsent, setUserConsent] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [cardUpdated, setCardUpdated] = useState(false);

  const listRef = useRef(null);

  // TODO: Simplify this classes, hard to debug.
  const cardCSS = `paymentCard x-sm:w-full cursor-pointer py-3.5 lg:py-5 px-6 lg:px-5 x-sm:mb-2 hover:text-black hover:bg-white rounded-3xl h-20 lg:h-full dashboard-card-${props.partnerName}`;
  const dueDateCSS = `paymentCard x-sm:mb-2 py-3.5 lg:py-5 px-6 lg:px-5 rounded-3xl h-full dashboard-card-${props.partnerName}`
  const cardCSSSelect = `activeCard x-sm:h-20`;
  const disableBtnCSS = `btn-disabled btn-disabled-${props.partnerName} x-sm:mt-4 text-gray-700 bg-gray-400 bg-opacity-40 cursor-not-allowed block font-medium text-center text-xl h-16 p-5 w-72 mt-4 rounded-full x-sm:w-full x-sm:ml-0 brand-btn-solid`;
  const enableBtnCSS = "text-black bg-white autopay-btn block text-center text-xl tracking-wide h-16 p-5 cursor-pointer w-72 mt-4 rounded-full x-sm:w-full x-sm:ml-0 brand-btn-solid";

  const BUTTON_MESSAGES = {
    enroll_autopay_button: `Enroll in Auto Pay`,
    update_autopay_button: `Update Auto Pay`,
    cancel_autopay_button: `Cancel Auto Pay`
  };

  const partnerName = props.partnerName !== 'ccbank' ? props.partnerName : null;
  const logo_image = getWhiteLogo(partnerName, false);

  useEffect(() => {
    async function createLinkToken() {
      postWithAuthHeader(GET_LINK_TOKEN, {}, EVENT.pay_bank_account_linked_token_fetch_failed).then((res: any) => {

        let token = res?.data?.link_token;
        ClientStorage.setLocal("link_token", token);
        ClientStorage.setLocal("redirectUrl", window.location.href);
        setLinkToken(token);
      });
    }

    let metadata = ClientStorage.getLocal("plaid-metadata");

    if (metadata === "null" || metadata === null) {
      createLinkToken();
    } else {
      if (ClientStorage.getLocal("plaid-metadata")) {
        let metadata = JSON.parse(ClientStorage.getLocal("plaid-metadata"));
        let data = Object.assign({}, metadata, {
          bank_token: ClientStorage.getLocal("bank_token"),
        });
        updatePaymentMethod(data);
        createLinkToken();
      }
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    track(EVENT.autopay_page_load_initated);
    Promise.allSettled([get(GET_PAYMENT_METHOD), get(PAYMENT_V3_API_URL), get(STATEMENTS_API_URL), get(ACCOUNT_V3_API_URL)])
      .then(([paymentDataRes, paymentsData, statementsDataRes, accountDataRes]) => {
        return [paymentDataRes, paymentsData, statementsDataRes, accountDataRes].map((response) => {
          return response.status === "fulfilled" ? response.value : null;
        });
      })
      .then(([paymentMethodData, paymentsData, statementsData, accountData]) => {
        console.log('paymentsData --- ', paymentsData);
        if(paymentsData?.payment_home_card?.is_migration_in_progress){
          setShowMigrationError(true);
        }else{
          setShowMigrationError(false);
          setAutopayStatusData(paymentsData);
          setAccountData(accountData);
          if (!autopayStatusData?.auto_pay?.due_date_time_zone) {
            appSentryCaptureError('due_date_time_zone_not_found', {
              auto_pay: autopayStatusData?.auto_pay
            }, new Error('due_date_time_zone_not_found') as AxiosError<any>);
          }
          setShowConsent(paymentsData?.auto_pay?.disclaimer_confirmation?.should_show);
          let formattedDate = moment(paymentsData?.auto_pay?.due_date, 'MM-DD-YYYY').format('Do');
          formattedDate = (formattedDate.toLocaleLowerCase() === 'invalid date') ? 'na' : formattedDate;

          setIsStatements(statementsData?.sections.length);
          const due_date = paymentsData?.auto_pay?.due_date;

          const activeAutoPaySchedule = paymentsData?.auto_pay?.schedule;
          setAutoPayRespData(paymentsData);
          if (activeAutoPaySchedule?.id) {
            setShowCancelUpdateButton(true);
            setAutopayScheduleId(activeAutoPaySchedule.id);
            setEnrolledSelectedMethod(activeAutoPaySchedule.type);
            setSelected(activeAutoPaySchedule.type);
          }
          if (due_date) {
            setIsAutopayAvailable(true);
          }

          setUpDisplayMessages(paymentsData?.auto_pay, activeAutoPaySchedule?.type);

          setDueDate(formattedDate);
          setMinPaymentAmount(accountData?.credit_line?.minimum_payment);
          setStatementBalAmount(accountData?.credit_line?.statement_balance);

          track(EVENT.autopay_page_loaded);

          if (paymentMethodData?.methods.length) {
            setPaymentMethods(paymentMethodData?.methods);
            setSelectedPaymentMethod(paymentMethodData.methods[0] ?? []);
          }
        }
      })
      .catch((er) => {
        track(EVENT.autopaypage_load_failed);
        setShowPaymentFailure(true);
      })
      .finally(() => setLoading(false));
  }, []);

  function setUpDisplayMessages(msg, selectedType) {
    setTitle("Auto Pay");
    const description = (selectedType === undefined || selectedType === '') ? msg.default : (selectedType === 'MINIMUM-PAYMENT' ? msg.min_pay_msg : msg.stmt_pay_msg);
    setDescription(description);
    setDisclaimer(msg?.disclaimer);

    let messages = {
      default: msg.default,
      minumum_payment: msg.min_pay_msg,
      statement_balance: msg.stmt_pay_msg,
    }
    setDisplayMessages(messages);
  };

  const updateDisplayMessages = () => {
    setLoading(true);
    get(GET_AUTOPAY_STATUS)
      .then(res => {
        setTitle("Auto Pay");
        setDescription(res.auto_pay?.message);
        setLoading(false);
      })
      .catch(err => {
        setShowPaymentFailure(true);
        setLoading(false);
      });
  }

  const updateCard = (key) => {
    const description = (key === 'MINIMUM-PAYMENT') ? displayMessages.minumum_payment : displayMessages.statement_balance;
    setSelected(key);
    setCardUpdated(true);

    if (isAutopayAvailable && selectedPaymentMethod) {
      if(showConsent){
        if (userConsent){
          setEnrollButtonDisabled(false);
          setDescription(description);
        }
      }else{
        setEnrollButtonDisabled(false);
        setDescription(description);
      }
    }
  };

  function onSuccess(token: any, metadata: any) {
    track(EVENT.onb_bank_connected);
    updatePaymentMethod(metadata);
  }

  const showOptions = () => {
    setShowPaymentMethod(!showPaymentMethod);
  };

  const updatePaymentMethod = (data) => {
    setBtnLoading(true);

    data.hasOwnProperty('status') && delete data.status;

    postWithAuthHeader(SET_PAYMENT_METHOD, data, EVENT.payment_failed)
      .then((res: any) => {
        ClientStorage.deleteLocal("bank_token");
        ClientStorage.deleteLocal("plaid-metadata");
        ClientStorage.deleteLocal("is_plaid_callback");

        get(GET_PAYMENT_METHOD).then((res) => {
          setPaymentMethods(res.methods);
          setSelectedPaymentMethod(res?.methods?.[0]);
          isAutopayAvailable && selected && setEnrollButtonDisabled(false);
        });
      })
      .catch((er) => {
        setShowPaymentFailure(true);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const enrollAutoPay = () => {
    const data = {
      frequency: "DUE-DATE",
      type: selected,
      method_id: selectedPaymentMethod.id,
    };
    setLoading(true);

    postWithAuthHeader(PAYMENT_API, data, EVENT.auto_pay_payment_posting_failed)
      .then((res: any) => {
        setLoading(false);
        if (res.data?.status === 'ACTIVE' || res.data?.status === 'SCHEDULING') {
          setAutopaySuccessData(res.data);
          setShowCancelUpdateButton(true);
          setAutopayScheduleId(res.data?.id);
          setEnrolledSelectedMethod(selected);
          updateDisplayMessages();
          setShowAutopaySuccess(true);
          setShowPaymentFailure(false);
        }
      })
      .catch(err => {
        setLoading(false);
        setShowAutopaySuccess(false);
        setShowPaymentFailure(true);
      });
  };

  const cancelAutoPay = () => {
    const applicationToken = getApplicationToken();

    axios({
      url: `${CANCEL_AUTOPAY}?schedule_id=${autopayScheduleId}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${applicationToken}`,
        ...commonHeaders()
      },
    }).then(res => {
      window.location.reload();
    })
      .catch(err => {
        setShowPaymentFailure(true);
      })
  };

  const updateAutoPay = () => {
    const url = `${UPDATE_AUTOPAY}?schedule_id=${autopayScheduleId}`;
    const applicationToken = getApplicationToken();
    const data = {
      frequency: "DUE-DATE",
      type: selected,
      method_id: selectedPaymentMethod.id,
    };
    setLoading(true);

    axios({
      url: url,
      method: "PUT",
      data: data,
      headers: {
        Authorization: `Bearer ${applicationToken}`,
        ...commonHeaders()
      },
    })
      .then(res => {
        setLoading(false);
        setAutopaySuccessData(res.data);
        setAutopayScheduleId(res.data.id);
        updateDisplayMessages();
        setShowAutopaySuccess(true);
      })
      .catch(err => {
        setLoading(false);
        setShowAutopaySuccess(false);
        setShowPaymentFailure(true);
      });
  }

  if (Loading) {
    return <LoadingIcon className="mx-auto" />;
  }


  const cardsMap = [
    {
      title: "Pay Minimum Due",
      key: "MINIMUM-PAYMENT",
      text1: "Pay Minimum",
      text2: "Due",
      amount: parseFloat(minPaymentAmount) || 0,
      id: "card1",
    },
    {
      title: "Pay Statement Balance",
      text1: "Pay Statement",
      text2: "Balance",
      key: "STATEMENT-BALANCE",
      amount: parseFloat(statementBalAmount) || 0,
      id: "card2",
    }
  ];

  const dueDateContent =
    (
      <div className="w-1/5 x-sm:w-full pt-4 pb-2 lg:py-4 px-4 h-full" data-testid='autoPayDueDate'>
        <div key="card3" data-testid="card3" id='card3' className={`${dueDateCSS} border-transparent border autoPayDueDate-border`}>
          <div className="justify-between check x-sm:flex md:flex lg:block">
            <div>
              <p className="text-xs x-sm:mt-2 text-1-heading-3 text-caption-6 text-t1-color">{autopayConst.due}</p>
            </div>
            <div>
              <p className="text-2xl font-bold my-4 x-sm:mt-0 text-1-heading-2 text-t1-color">{dueDate || 'na'}</p>
              <p className="text-b7">{autopayStatusData?.auto_pay?.due_date_time_zone}</p>
            </div>
          </div>
        </div>
      </div>
    );

  const paymentMethodSelectedContent =
    (
      <div className="py-3.5 lg:py-5 px-6 lg:px-5 relative bg-white cursor-pointer text-xl rounded-3xl block text-black z-50 h-20 lg:h-28" onClick={showOptions}>
        <div className="uppercase text-xxs text-caption-6">{autopayConst.payFrom}</div>
        <div className="flex items-center justify-between cursor-pointer">
          <div className="flex items-center">
            <img
              className="w-12 rounded-lg"
              src={selectedPaymentMethod?.thumbnail_url}
              alt="bank_logo"
            />
            <div className="pl-2">
              <p className="text-sm capitalize text-heading-5">
                {selectedPaymentMethod?.bank_name}{" "}
                {selectedPaymentMethod?.account_type}{" "}
                {selectedPaymentMethod?.account_last_4_digits}
              </p>
            </div>
          </div>
          <img className="w-5" src={`${IMAGE_BASE_URL}/down-arrow.png`} alt="down" />
        </div>
        <div
          className={
            showPaymentMethod
              ? "autoPayPaymentOptions payment_active z-20 x-sm:-bottom-44 -bottom-36"
              : "autoPayPaymentOptions"
          }
        >
          <ul
            className="px-7 pt-4 max-h-72 overflow-y-auto"
            ref={listRef}
          >
            {paymentMethods.map((method) => {
              return (
                <li
                  key={method.id}
                  className="cursor-pointer pb-4 mt-4"
                  onClick={() => {
                    method && setSelectedPaymentMethod(method);
                    setShowPaymentMethod(false);
                  }}
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <img
                        className="w-12 mr-4 rounded-lg"
                        src={method?.thumbnail_url}
                        alt="bank_logo"
                      />
                      <div>
                        <p className="uppercase text-xxs">
                          {method?.bank_name}
                        </p>
                        <p className="text-sm capitalize">
                          {method?.account_type}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="text-sm self-center font-bold flex items-center">
                      <p>...{method?.account_last_4_digits}</p>
                      <div className="w-4 ml-4">
                        {selectedPaymentMethod?.id === method?.id && (
                          <img src={`${IMAGE_BASE_URL}/check.png`} alt="selected" />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
            <li className="pb-3 text-center mt-2" onClick={e => e.stopPropagation()}>
              {showPaymentMethod && (
                <span className="flex relative border-t">
                  <LinkBankAccountButton
                    link_token={linkToken}
                    handleSubmit={onSuccess}
                    title="Add bank"
                    type="link"
                    buttonClasses="ml-auto mr-auto mt-0 w-full m-t-0 p-4"
                  />
                  {btnLoading && (
                    <div className="loading-black align-spinner"></div>
                  )}
                </span>
              )}
            </li>
          </ul>
        </div>
        {(autoPayRespData && autoPayRespData?.auto_pay?.account_disclaimer) && <p data-testid="account_disclaimer" className="text-b11 x-sm:mt-4 sm:mt-4 md:mt-8 lg:mt-8 xl:mt-8">{autoPayRespData.auto_pay.account_disclaimer}</p>}
      </div>
    );

  let paymentMethodCss = `relative md:mt-4 lg:mt-0 bg-white h-24 lg:h-full w-full cursor-pointer text-xl rounded-3xl text-black x-sm:w-11/12 x-sm:mx-auto x-sm:mb-14`;
  return (
    <div className="container-dashboard x-sm:w-full w-3/5" onClick={() => showPaymentMethod ? setShowPaymentMethod(false) : null}>
      <div
        className={`dashboard-${props.partnerName} dashboard-profile-${props.partnerName} auto-pay-${props.partnerName} xl:h-screen lg:h-screen py-11 px-12 x-sm:w-full x-sm:px-8 x-sm:pb-0 flex flex-col justify-between`}
      >
        <div onClick={() => window.location.assign(DASHBOARD_PATH)} className={`fixed cursor-pointer w-10 top-10 right-12 rounded-full x-sm:right-4 page-close`} id="closeAutopay">
          <img src={`${IMAGE_PREFIX}/close-page.png`} alt="Cancel" />
        </div>
        <div data-testid="logo" className="top-10 w-full">
          <img className="w-48" src={logo_image} alt="logo" />
          <div className="text-5xl pt-20 w-3/4 x-sm:w-full x-sm:text-4xl x-sm:-mt-10 text-1-heading page-title" id="autopayTitle">{title}</div>
          <div className='x-sm:block'>
            <div className="text-lg pt-3 x-sm:w-full x-sm:text-sm text-2-heading page-sub-title mb-2 text-t2-color" id="autopayDescription">{description}</div>
            {!showConsent && <div className="flex pt-4 txt-type-div x-sm:block x-sm:pl-0 text-xs x-sm:text-sm text-1-heading-3">{ReactHtmlParser(disclaimer)}</div>}
          </div>
        </div>
        {!showAutopaySuccess && !showPaymentFailure && !showMigrationError && <div className="md:py-8 lg:py-8 xl:py-8 x-sm:py-4 sm:py-4">
          <div>
            <div className={`-mx-8 flex h-full lg:h-40 x-sm:flex-col md:flex-col items-center lg:flex-row lg:items-stretch x-sm:justify-center md:justify-center`}>
              {cardsMap.map((card) => {
                return (
                  <label className="w-1/2 x-sm:w-full p-4 x-sm:-mb-5" key={card.id} htmlFor={card.id}>
                    <div
                      data-testid={card.id}
                      id={card.id}
                      className={
                        selected === card.key
                          ? `${cardCSSSelect} ${cardCSS}`
                          : cardCSS
                      }
                      onClick={() => updateCard(card.key)}
                    >
                      <div className="flex flex-row justify-between check">
                        <div>
                          <p className="text-xs -mt-2 mb-1 ml-0.5 text-1-heading-3 text-caption-6 text-t1-color">{autopayConst.amount}</p>
                          <div className="x-sm:flex md:flex lg:block">
                            <p className="text-2xl font-bold tracking-wide x-sm:mr-2 md:mr-2 text-1-heading-2 text-heading-5 text-t1-color">{card.text1}</p>
                            <p className="text-2xl font-bold tracking-wide x-sm:mr-2 md:mr-2 text-1-heading-2 text-heading-5 text-t1-color">{card.text2}</p>
                          </div>
                        </div>
                        <div className="roundCheckbox">
                          <input
                            name="type"
                            type="radio"
                            id={card.id}
                            checked={selected === card.key}
                            defaultChecked={selected === card.key}
                          />
                          <label htmlFor={card.id}></label>
                        </div>
                      </div>
                    </div>
                  </label>
                )
              })
              }
              {dueDateContent}
              <div className="flex justify-center items-center py-2 lg:py-4 lg:px-5 w-full lg:w-1/2">
                <div className={selectedPaymentMethod ? paymentMethodCss : `${paymentMethodCss} activeCard`} data-testid='paymentMethods' id='paymentMethods'>
                  {selectedPaymentMethod ? paymentMethodSelectedContent
                    : (
                      <>
                        <div className="uppercase text-xxs ml-8 mt-4 x-sm:mt-3.5 x-sm:ml-6 text-caption-6">{autopayConst.payFrom}</div>
                        <div className="flex">
                          <LinkBankAccountButton
                            link_token={linkToken}
                            handleSubmit={onSuccess}
                            title="Add Bank"
                            type="autopay"
                            autoPayButtonClasses="add-pay-method-btn"
                          />
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="mt-12 md:mt-20">
              {showConsent && <div className="flex items-center justify-center pt-4 txt-type-div x-sm:pl-0 text-xs x-sm:text-sm text-1-heading-3">
                  <div className="mr-3">
                    <input
                      type="checkbox"
                      name="user-consent"
                      id="user-consent-checkbox"
                      className="invisible absolute"
                      data-testid='achAgreementChkbox'
                      onChange={() => {
                        let button_type = userConsent ? "negative_button" : "positive_button";
                        track(autopayStatusData?.auto_pay?.disclaimer_confirmation?.content?.alert?.[button_type]?.analytics_info?.event_name);
                        setUserConsent(!userConsent);
                        setEnrollButtonDisabled(showConsent
                          ? !(!userConsent && cardUpdated)
                          : !cardUpdated);
                      }}
                    />
                    <label className="df-icon-unchecked cursor-pointer" htmlFor="user-consent-checkbox">
                      <IconUnCheck/>
                    </label>
                    <label className="df-icon-checked cursor-pointer" htmlFor="user-consent-checkbox">
                      <IconCheck/>
                    </label>
                  </div>
                  <div className="text-b10 text-t1-color">{ReactHtmlParser(disclaimer)}</div>
                </div>
              }
              {!showCancelUpdateButton &&
                <BtnNext
                  text={BUTTON_MESSAGES.enroll_autopay_button}
                  className={`x-sm:mt-4 enroll-autopay-btn-${props.partnerName} ${enrollButtonDisabled ? `btn-disabled btn-disabled-${props.partnerName} x-sm:mt-4 text-gray-700 bg-gray-400 bg-opacity-40 cursor-not-allowed block font-medium text-center text-xl h-16 p-5 w-72 ml-auto mr-auto mt-8 rounded-full x-sm:w-full x-sm:ml-0 brand-btn-solid` : "text-black bg-white autopay-btn block text-center text-xl tracking-wide h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full x-sm:w-full x-sm:ml-0 brand-btn-solid"}`}
                  onClick={enrollAutoPay}
                  disabled={enrollButtonDisabled}
                  data-testid='enrollAutopayButton'
                  id="enrollAutopayButton"
                />
              }
              {
                showCancelUpdateButton && (
                  <div className="flex justify-center x-sm:block">
                    <BtnNext
                      text={BUTTON_MESSAGES.cancel_autopay_button}
                      className={`text-gray-100 autopay-cancel-${props.partnerName} border cursor-pointer block text-center text-xl x-sm:text-lg x-sm:font-medium tracking-tight h-16 p-5 w-72 mt-4 mr-4 x-sm:mr-1 rounded-full x-sm:mt-4 x-sm:w-full x-sm:ml-0 brand-btn-void`}
                      onClick={cancelAutoPay}
                      disabled={false}
                      data-testid='cancelAutopayButton'
                    />
                    <BtnNext
                      text={BUTTON_MESSAGES.update_autopay_button}
                      className={`x-sm:mt-4  enroll-autopay-btn-${props.partnerName} ${(showConsent && !userConsent) || (!cardUpdated) ? disableBtnCSS : enableBtnCSS}`}
                      onClick={updateAutoPay}
                      disabled={showConsent
                        ? !(userConsent && cardUpdated)
                        : !cardUpdated
                      }
                      data-testid='updateAutopayButton'
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>}
        <div className="flex justify-between x-sm:flex-col-reverse items-center">
          {props.partnerName === "deserve_edu" ? (<img className="img-powered-by" src={`${IMAGE_BASE_URL}/deserve_edu/powered_by_dark.png`} alt="" />) : <span></span>}
          {(autoPayRespData && autoPayRespData?.auto_pay.institution_unavailable_info_msg) && <p data-testid="institution_unavailable_info_msg" className="text-b7 institution_unavailable_info_msg x-sm:text-center sm:text-center md:text-right lg:text-right xl:text-right pb-6">{autoPayRespData.auto_pay.institution_unavailable_info_msg}</p>}
        </div>
      </div>
      {showAutopaySuccess && (
        <AutopaySuccess
          meta={autopaySuccessData}
          first_name={autopayStatusData.payment_detail.metadata.user_display_name}
          partnerName={props.partnerName}
          dueDate={dueDate}
        />
      )}
      {/* redirect to error page in case of API Error or Autopay Failure. */}
      {showPaymentFailure &&
        (
          <PaymentError
            makePayment={() => window.location.assign(AUTO_PAY)}
            partnerName={props.partnerName}
          />
        )}
      {showMigrationError &&
        (
          <MigrationInProgress
            partnerName={props.partnerName}
          />
        )
      }
    </div>
  );
}

export default Autopay;
