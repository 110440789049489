import React, { useEffect } from "react";
import Toast from "../../components/Toast";

import { IMAGE_PREFIX } from "../../constants/urls.constants";
import {
  clearHomeRouteState,
  getCachedPhoneNumber,
  trackUTM,
} from "../../utils/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { AutoHideToast } from "../../components/AutohideToast";
import { NewSignInFlowActions } from "../../components/NewSignInFlowActions";

const CCBankPeak = (props) => {
  const location: any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  trackUTM("marketingData", window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <div className="ccbank-index-page">
      <div className="md:container m-auto lg:px-4">
        {title && <AutoHideToast title={title} timeout={6000} />}
        <div className="w-full x-sm:pb-0 py-7 flex flex-col">
          {message && (
            <Toast
              cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center"
              text={message}
              timeout={6000}
            />
          )}
          <div className="flex justify-between x-sm:items-center w-40 x-sm:pl-8">
            <a className="inline-block" href="/">
              <img src={`${IMAGE_PREFIX}/logo.png`} alt="logo" />
            </a>
          </div>
          <div className="cb-content-container x-sm:px-4 pb-4 flex flex-col lg:flex-row-reverse justify-center lg:justify-between x-sm:flex-col-reverse x-sm:pt-4">
            <img
              src={`${IMAGE_PREFIX}/hero.png`}
              className="ccbank-img x-sm:mx-auto x-sm:w-10/12 lg:ml-24 w-1/3"
              alt="ccbank"
            />
            <div className="lg:w-2/5 lg:self-center">
              {!phoneNumber && (
                <h1 className="x-sm:text-3xl text-center lg:text-left page-main-heading">
                  <span className="block">Apply and get a card  you can use within minutes</span>
                </h1>
              )}

              {phoneNumber && (
                <h1 className="x-sm:text-3xl text-center lg:text-left page-main-heading">
                  Welcome back
                </h1>
              )}
              {!phoneNumber && (
                <p className="page-sub-heading-text my-8 lg:w-80 x-sm:text-center md:text-left lg:text-left">
                  See if you’re approved without a negative impact to your credit*.
                </p>
              )}

              {phoneNumber && (
                <p className="page-sub-heading-text mt-6 x-sm:text-center md:text-center lg:text-left">
                  Continue with your phone number verification to sign in
                </p>
              )}
              <span className="border block sign-btn-devider x-sm:block sm:hidden md:hidden lg:hidden" />
              <div className="x-sm:mt-4 text-center x-sm:block sm:hidden md:hidden lg:hidden">
                <span className="text-b4">Sign in or Apply below</span>
              </div>
              <NewSignInFlowActions showOnlySignIn={false} buttonsParentClass="x-sm:w-full sm:w-72 md:w-72 lg:w-72 lx:w-72" />
              <div className="x-sm:mt-4 text-left x-sm:block sm:hidden md:hidden lg:hidden x-sm:pl-4">
                <span className="text-b7">*Subject to credit approval </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="index-footer">
        <div className="md:container m-auto">
          <p className="footer-text">
            *Accepting your credit offer will result in a hard credit inquiry which may impact your credit score
          </p>
        </div>
      </footer>
    </div>
  );
};

export default CCBankPeak;
