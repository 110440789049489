import Cookies from "js-cookie";
import axios from "axios";
import {
  APPLICATION_BASE_URL,
  ERROR_PAGE_URL,
} from "../constants/urls.constants";
import { track, trackFailure } from "./analytics";
import ClientStorage from "./client-storage";
import { v4 as uuidv4 } from "uuid";
import jwtDecode from "jwt-decode";
import {getApplicationToken} from "./helpers";
import * as Sentry from "@sentry/react";
import { DEFAULT_PRODUCT, PRODUCT_ALIAS_HEADER, PRODUCT_ALIAS_KEY } from "../constants/product-constants";


const getOperatingSystem = (window) => {
  let operatingSystem = "Not known";
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1)
    operatingSystem = "Windows 10";
  if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1)
    operatingSystem = "Windows 8.1";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1)
    operatingSystem = "Windows 8";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1)
    operatingSystem = "Windows 7";
  if (window.navigator.appVersion.indexOf("Mac") !== -1)
    operatingSystem = "MacOS";
  if (window.navigator.appVersion.indexOf("X11") !== -1)
    operatingSystem = "UNIX OS";
  if (window.navigator.appVersion.indexOf("Linux") !== -1)
    operatingSystem = "Linux OS";

  return operatingSystem;
};

function token() {
  return getApplicationToken();
}

function userId() {
  try {
    const _token = token();

    if (_token) {
      // Only do when token available
      let user_id = jwtDecode(_token)["user_id"];
      localStorage.setItem("user_id", user_id);
      return user_id;
    }
    return null; // If user is trying to sign in or sign up initially we don't have token, return user id null
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}


const getProductIdAlias = () => {

    /**
     * TODO (1): Anujit - WWT-4430 - We can remove the if-else condition and abstract the code into a separate class and its implementations based on tenant
     */

  const product_alias = ClientStorage.getLocal(PRODUCT_ALIAS_KEY);

  if(product_alias) {
    return {
      PRODUCT_ALIAS_HEADER: product_alias || DEFAULT_PRODUCT
    }
  }

  return {}

}


export const header = () => {
  return {
    request_trace_id: uuidv4(),
    device: window?.navigator?.userAgent,
    os_version: getOperatingSystem(window),
    app_version: window?.navigator?.appVersion,
    app_name: window?.navigator?.appName,
    // @ts-ignore: appConfig defines run time 
    env: window?.appConfig["ENV_NAME"] || window?.appConfig["NODE_ENV"],
    user_id: userId(),
    time_zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
    platform: 'web',
    internet_type: window?.navigator["connection"]
      ? window?.navigator["connection"]["effectiveType"]
      : "",
    session_trace_id: ClientStorage.getLocal('session_trace_id'),
    ...getProductIdAlias()
  };
}

export const commonHeaders = (headers?: object) => {
  return {
    ...header(),
    ...headers ? headers : {},
  };
};

export let get = async (url: string) => {
  let applicationToken = getApplicationToken();
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${applicationToken}`,
      ...commonHeaders(),
    },
  }).then((res) => {
    if (res.status === 401) {
      window.location.href = APPLICATION_BASE_URL;
    } else if (res.ok) {
      return res.json();
    } else {
      return { hasError: true };
    }
  });
};

export const getEphemeralTokenData = async (url: string) => {
  let applicationToken = getApplicationToken();
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${applicationToken}`,
      ...commonHeaders(),
    },
  })
  .then((data) => data.json())
  .then((res) => {
    return res;
  })
  .catch((err) => Sentry.captureException(new Error(`SSN ephemeral token failed - ${err}`)));
};

export let postWithAuthHeader = (
  url: string,
  data: any,
  event?: any
): Promise<any> => {
  let applicationToken = getApplicationToken();

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${applicationToken}`,
          ...commonHeaders(),
        },
      })
      .then(function (response) {
        if (response.status === 401) {
          window.location.href = APPLICATION_BASE_URL;
        } else if (
          response.data &&
          response.data.steps &&
          response.data.steps.length > 0 &&
          response.data.steps[0].identifier === "show_error_action"
        ) {
          // TODO: show correct errors here
          // TODO: when entering incorrect zip code, response data incorrectly returns success_state

          const chapter = response.data.steps[0]["ui_template_type"];
          const errorMessage =
            response.data.steps[0][chapter]["success_state"]["title"];
          const errorAction =
            response.data.steps[0][chapter]["success_state"][
              "first_action_title"
            ];
          window.location.href = `${ERROR_PAGE_URL}?errorMessage=${errorMessage}&errorAction=${errorAction}`;
        }
        resolve(response);
      })
      .catch((er) => {
        ClientStorage.deleteLocal("bank_token");
        ClientStorage.deleteLocal("plaid-metadata");
        ClientStorage.deleteLocal("redirectUrl");
        ClientStorage.deleteLocal("link_token");
        ClientStorage.deleteLocal("is_plaid_callback");

        trackFailure(event, er);

        reject(er);
      });
  });
};

export async function post(
  url: string,
  data?: object,
  config?: {
    headers: object
  }
): Promise<any> {
  return axios
    .post(url, data, {
      headers: {
        ...commonHeaders(config?.headers),
      },
    })
    .then((res) => {
      return res.data;
    });
}
