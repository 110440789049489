import React, { useEffect, useState, Fragment } from 'react'
import { PROFILE_API_URL, IMAGE_BASE_URL } from '../constants/urls.constants';
import ArrowLeft from '../components/ArrowLeft';
import Loading from '../components/Loading';
import { get } from "../utils/client";
import PaymentError from './Payment_Error';
import { CONTACT_US_PATH } from '../constants/route-paths.constants';
import { copyToClipboard } from '../utils/helpers';

type Props = { partnerName: String };


const ContactUs = (props: Props) => {
  const [contactData, setContactData] = useState(null);
  const [contactInfo, setContactInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showProfileError, setShowProfileError] = useState(false);


  useEffect(() => {
    setLoading(true);

    get(PROFILE_API_URL)
      .then((accountDataRes) => {
        let contactDetails = accountDataRes?.sections?.filter(x => x.title === "OTHER")[0]?.cells.filter(x => x.title === "Contact");
        let content = contactDetails[0]?.detail?.content[0];
        let cell = content?.profile_template_b;
        let contactSectionCells = cell.section?.cells;

        setContactData(cell);
        setContactInfo(contactSectionCells);
      })
      .catch((err) => {
        setShowProfileError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleBack = () => {
    window.location.assign('/dfc/profile');
  }

  const arrowColor = document.body.style.getPropertyValue("--theme-card-text")


  return (
    loading ? <Loading /> :
      <div className={`dashboard-${props.partnerName} dashboard-profile-${props.partnerName} contact-us-${props.partnerName} flex x-sm:block md:dashboard-contact-us lg:dashboard-contact-us`}>
        <div className="flex flex-col h-screen x-sm:w-full  lg:w-3/4 px-14 pb-8 pt-12 x-sm:px-4 x-sm:pt-4">
          <div data-testid="backButton" id="contact-arrowleft" className="back-button-arrow opacity-100 s-sm:mb-14" onClick={handleBack} >
            <ArrowLeft fillColor={arrowColor} />
          </div>
          {
            contactData?.default_state &&
            <h1 data-testid="contactTitle" className="text-main-edit-address pb-7 x-sm:ml-4">{contactData?.default_state?.title}</h1>
          }
          <div className="p-7 rounded-2xl border border-transparent theme-card-bg x-sm:mb-8 x-sm:w-full sm:w-96 md:w-full lg:w-96 xl:w-96">
            {contactInfo?.map((data, index) => {
              return (
                <div data-testid="contactCard" key={index} className="pb-6">
                  <div className="text-base font-bold text-heading-4 text-color">{data?.title}</div>
                  <div className="text-base text-b3 text-color mt-1.5 flex w-max min-w-full">
                    <span className=''>{data?.subtitle}</span>
                    <span title="copy to clipboard" onClick={() => copyToClipboard(data?.subtitle, data.title + " copied")} className='cursor-pointer ml-auto'>
                      <img src={`${IMAGE_BASE_URL}/copy-icon.svg`} alt="copy" />
                    </span>
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>
        {
          showProfileError && <PaymentError
            makePayment={() => window.location.assign(CONTACT_US_PATH)}
            partnerName={props.partnerName}
            type="profile"
          />
        }
      </div >
  )
}

export default ContactUs;