import {useEffect} from 'react';
import emitter from "../utils/emitter";
import {track} from "../utils/analytics";
import * as Scroll from 'react-scroll';
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { QRCodeSVG } from "qrcode.react";
import ReactHtmlParser from "html-react-parser";
import { autoPoll } from "../utils/auto_poll";

type Props = { 
  CurrentStep: { ui_template_type: string }; 
  breadcrumbs: Object;
  workflow: Object,
  setWorkflow: Function
};

function TemplateO(props: Props) {
  let partnerName = window['appConfig'].PARTNER_NAME;
  let Link = Scroll.Link;
  const ModelName = props.CurrentStep.ui_template_type;
  const ModelData = props.CurrentStep[ModelName];
  const header = ModelData.default_state.header;
  const title = ModelData.default_state.title;
  const subtitle = ModelData.default_state.subtitle;
  const subtitle_mobile = ModelData.default_state.subtitle_mobile;
  const next_steps = ModelData.next_steps;
  const QRCodeImageURL = window["appConfig"]["CLIENT_APP_DOWNLOAD_URL"];

  useEffect(() => {
    const intervalId = autoPoll(props.workflow, props.setWorkflow);
    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.CurrentStep['identifier'] });
    return () => {
      clearInterval(intervalId);
    }
  }, [props.CurrentStep, props.breadcrumbs, props.workflow, props.setWorkflow]);

  track('onb_credit_approved_viewed');

  const handleNext = (e: Event) => {
    e.preventDefault();
    let userAgent = navigator.userAgent;

    track("onb_credit_approved_continue_clicked", {download_url: QRCodeImageURL});

    if (
      userAgent.match(/Android/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
    ) {
      track("onb_app_download_mobile_web_button_clicked", {download_url: QRCodeImageURL});
    }

    window.location.href = QRCodeImageURL;

    return null;
  }

  return (
    <div key={props.CurrentStep['step_info'].step_identifier} id={props.CurrentStep['step_info'].step_identifier} className='congrats-content'>
      <p className="theme-text-color text-xs px-6 mt-20 text-center font-t2" data-testid="header">{header}</p>
      <p className="theme-text-color text-2xl lg:text-4xl px-6 lg:px-0 mt-2 text-center" data-testid="title">{ReactHtmlParser(title)}</p>
      <p className="hidden lg:block theme-text-color text-xl px-6 lg:px-0 text-center font-t2" data-testid="subtitle">{ReactHtmlParser(subtitle)}</p>
      <p className="lg:hidden theme-text-color text-2xl px-6 text-center font-t2" data-testid="subtitle_mobile">{ReactHtmlParser(subtitle_mobile)}</p>
      <div className="content-container congrats-content">
        <div className='lg:hidden text-center mt-12 w-64 h-16 mx-auto congrats-page-btn'>
          <Link
            to={`${1}`}
            containerId="containerElement"
            className='block h-full py-4 font-medium text-xl tracking-tighter onsent-button-submit cursor-pointer button-with-custom-text rounded-full text-white'
            onClick={handleNext}
          >
            {ModelData.default_state.first_action_title}
          </Link>
        </div>
        <div className='lg:hidden theme-text-color font-light text-lg mt-14 mx-10 text-center font-t2'>{ReactHtmlParser(next_steps.sub_title_mobile)}</div>
        <div className='theme-text-color mt-12 w-64 lg:w-full mx-auto lg:flex'>
          {next_steps.steps.map((step, index) => {
            return (
              <div>
                <div className='flex'>
                  <div className='congrats-icon-img-container'><img className='congrats-icon lg:mx-auto' src={`${IMAGE_PREFIX}/${step.left_container.left_image.name}.png`} /></div>
                  <div className='congrats-icon-text-container lg:pl-5'>
                    <div className='font-t2 color-theme'>{step.title.header}</div>
                    <div className='text-xs font-t2'>{step.title.text}</div>
                    {index === 2 && <div className='mb-28 lg:mb-14 sub-text txtCreditScore rounded-full font-normal mt-1.5 w-max px-2.5 lg:px-1.5 py-0.5 font-t2'>{ReactHtmlParser(step.subtitle.text)}</div>}
                  </div>
                  {index !== 2 && <div className='hidden lg:block vertical-divider mx-5 opacity-20'></div>}
                </div>
                {index !== 2 && <hr className='lg:hidden my-4 opacity-20 ml-auto mr-0' />}
              </div>
            );
          })}
        </div>
        <div className='hidden lg:block relative'>
          <div className={`congrats-qr-bg-${partnerName} mx-auto`}></div>
          <div className={`pt-8 w-1/2 ${partnerName === 'amc' ? '' : 'mx-auto'} bg-white rounded-2xl congrats-qr-block-${partnerName}`}>
            <div className='congrats-qr-block-inner'>
              <div className="font-light text-xl text-center font-t2">{ModelData.default_state.first_action_title}</div>
              <div className="font-light text-xs mt-2 text-center font-t2">{ReactHtmlParser(next_steps.sub_title)}</div>
              { track("onb_app_qrcode_displayed", {download_url: QRCodeImageURL}) }
              { track("onb_next_steps_viewed", {download_url: QRCodeImageURL}) }
              <QRCodeSVG className='mx-auto' size={250} includeMargin={true} value={QRCodeImageURL} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateO;
