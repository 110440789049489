export const StepIdentifier = {
  email: 'email',
  email_verify: 'email_verify',
  onb_steps_instruction: 'onb_steps_instruction',
  confirm_name_and_email: 'confirm_name_and_email',
  get_phone_number: 'get_phone_number',
  phone_verify: 'phone_verify',
  phone_upload: 'phone_upload',
  get_birthday: 'get_birthday',
  search_street_address: 'search_street_address',
  get_ssn: 'get_ssn',
  get_employment_status: 'get_employment_status',
  get_current_employment_status: 'get_current_employment_status',
  get_income_type: 'get_income_type',
  get_primary_income_source: 'get_primary_income_source',
  get_income: 'get_income',
  get_living_situation: 'get_living_situation',
  get_rent: 'get_rent',
  card_pre_approval_agreement: 'card_pre_approval_agreement',
  card_pre_approval_agreement_type_b: 'card_pre_approval_agreement_type_b',
  add_bank: 'add_bank',
  get_bank_account_age: 'get_bank_account_age',
  upload_bank_token: 'upload_bank_token',
  card_approved: 'card_approved',
  card_approved_v3: 'card_approved_v3',
  card_approved_v4: 'card_approved_v4',
  processing_docs: 'processing_docs',
  card_agreement: 'card_agreement',
  dashboard_whats_next: 'dashboard_whats_next',
  whats_next: 'whats_next',
  card_declined: 'card_declined',
  frozen_credit_bureau: 'frozen_credit_bureau',
  application_review: 'application_review',
  crunching_numbers: 'crunching_numbers',
  show_error_action_v2: 'show_error_action_v2',
  talking_to_boss: 'talking_to_boss',
  application_in_progress: 'application_in_progress',
  upload_docs: 'upload_docs',
  onb_logout: 'onb_logout',
  refresh_token: 'refresh_token',
  get_income_duration: 'get_income_duration',
  get_payment_type: 'get_payment_type',
  get_income_details: 'get_income_details',
  prove_v1: 'prove_v1'
}

export const OtpTimers = {
  resend_otp_timer: 30
}

export const PAYMENT_STATUS = {
  no_dues: "no_dues",
  new_user: "new_user",
  no_dues_current_balance: "no_dues_current_balance"
};

export const loginMessages = {
  default: {
    phoneTitle: "Phone Number",
    phoneSubTitle: "Welcome! Let’s find your account by entering your phone number.",
    emailSubTitle: "We sent a code to "
  },
  blockfi: {
    phoneTitle: "Phone Number",
    phoneSubTitle: "Enter the phone number that is associated with your <b>BlockFi account</b>.",
    emailSubTitle: "We sent a code to the email associated with your <b>BlockFi account</b> - "
  }
}

export function loginMessage(key) {
  const partnerName = window["appConfig"].PARTNER_NAME;
  return (loginMessages[partnerName] || loginMessages['default'])[key]
}

export const navLogo = {
  'sw': 'logo.svg',
  'earnest': 'logo.svg',
  'oppfi': 'logo.svg',
  'krowdfit': 'logo.svg',
  'customers_bank': 'logo.svg',
  'blockfi': 'logo.svg',
  'amc': 'logo-white.svg',
  'deserve': 'logo-black.svg',
  'deserve_edu': 'logo.png',
  'slm_ignite': 'logo.svg',
  'ccbank': 'ccbank_top_logo.svg',
}

export const cardLogo = {
  'sw': 'white-logo',
  'earnest': 'logo',
  'oppfi': 'logo',
  'krowdfit': 'white_logo',
  'customers_bank': 'white-logo',
  'blockfi': 'logo',
  'amc': 'white-logo',
  'deserve': 'logo',
}

export const VGS_COLLECT_VERSION = '2.18.5';
export const USER_INACTIVITY_TIMEOUT_IN_SECONDS = 1800;
