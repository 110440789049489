import { IMAGE_PREFIX } from "../constants/urls.constants";

export const getWhiteLogo = (partnerName, isLight = true) => {
  switch (partnerName) {
    case "krowdfit":
    case "customers_bank":
    case "amc":
    case "blockfi":
    case "ccbank":
    case "slm_ignite":
      return isLight
        ? `${IMAGE_PREFIX}/white_logo.png`
        : `${IMAGE_PREFIX}/logo.png`;
    default:
      return `${IMAGE_PREFIX}/logo.png`;
  }
};
